/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Muli:400,700&display=swap');

/* ----------------------------------------------
 * Generated by Animista on 2022-2-20 18:6:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shadow-drop-2-center
 * ----------------------------------------
 */
 @-webkit-keyframes shadow-drop-2-center {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px);
              transform: translateZ(50px);
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
              box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
  @keyframes shadow-drop-2-center {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px);
              transform: translateZ(50px);
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
              box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
  
  
html, body {width: 100%; max-width: 1800px;height: 100%;margin: auto; }
body { overflow-x: hidden;box-sizing: border-box;
         font-family: Roboto, "Helvetica Neue", sans-serif; }
